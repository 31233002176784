import Swiper, { FreeMode } from 'swiper';

export function filterChips(Alpine) {
  Alpine.data('filterChips', filterChipsComponent);

  function filterChipsComponent() {
    return {
      swiper: null,
      init() {
        const swiper = new Swiper(this.$root, {
          modules: [FreeMode],
          direction: 'horizontal',
          freeMode: true,
          slidesPerView: 'auto',
          mousewheel: true,
          breakpoints: {
            768: {
              enabled: false,
            },
          },
        });

        document.addEventListener('catalog-filter-changed', () => {
          this.$nextTick(() => swiper.update());
        });
      },
      destroy() {
        if (this.swiper) this.swiper.destroy();
      },
    };
  }
}
