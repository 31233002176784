import { ToastContent } from '../components/toast';
import { CartItem } from './cart';

export interface CustomEventMap {
  'show-toast': CustomEvent<ToastContent>;
  'catalog-filter-changed': CustomEvent<null>;
  'cart-item-added': CustomEvent<CartItem>;
}

export function addEventListener<K extends keyof CustomEventMap>(
  type: K,
  listener: (this: Document, ev: CustomEventMap[K]) => void
): void {
  document.addEventListener(type, listener);
}

export function dispatchEvent<K extends keyof CustomEventMap>(ev: CustomEventMap[K]): void {
  document.dispatchEvent(ev);
}

export function showToastEvent(toastContent: ToastContent) {
  return new CustomEvent('show-toast', {
    detail: toastContent,
    bubbles: true,
    composed: true,
    cancelable: true,
  });
}

export function catalogFilterChangedEvent() {
  return new CustomEvent('catalog-filter-changed', {
    detail: null,
    bubbles: true,
    composed: true,
    cancelable: true,
  });
}

export function cartItemAddedEvent(item: CartItem) {
  return new CustomEvent('cart-item-added', {
    detail: item,
    bubbles: true,
    composed: true,
    cancelable: true,
  });
}
