import { getProduct } from '../api/catalog';

export function infoDialogStore(Alpine) {
  Alpine.store('infoDialog', {
    isOpen: false,
    title: '',
    content: '',

    get isHeaderShown() {
      return this._isShown || this.isCatalogOpen || this.isSearchOpen;
    },
    open(title = '', content = '') {
      this.isOpen = true;
      this.title = title;
      this.content = content;
    },
    close() {
      this.isOpen = false;
    },
    setContent(content: string) {
      this.content = content;
    },
    async showBundleComponents(id: number) {
      Alpine.store('infoDialog').open('Состав букета', '');
      const product = await getProduct(id, ['bundleComponents']);

      const rows = product.bundleComponents.map(
        (component) => `
          <tr class="bundle-contents__row">
            <td class="bundle-contents__name-cell"><span
                class="bundle-contents__name">${component.title}</span>
            </td>
            <td class="bundle-contents__value-cell"><span
                class="bundle-contents__value">${component.quantity} шт</span></td>
          </tr>
        `
      );
      const table = `<table class="bundle-contents mb-6">${rows.join('')}</table>`;
      Alpine.store('infoDialog').setContent(table);
    },
  });
}
