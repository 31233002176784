import { initAnyQuery } from '../lib/anyQuery';
import { addEventListener } from '../lib/events';
import { md } from '../variables';

export function headerStore(Alpine) {
  Alpine.store('header', {
    _isShown: false,
    isCatalogOpen: false,
    isSearchOpen: false,

    get isHeaderShown() {
      return this._isShown || this.isCatalogOpen || this.isSearchOpen;
    },

    init() {
      addEventListener('cart-item-added', () => {
        if (window.innerWidth >= md) this.show();
      });
    },
    show() {
      this._isShown = true;
    },
    openCatalog() {
      this.isCatalogOpen = true;
      initAnyQuery();
    },
    openSearch() {
      this.isSearchOpen = true;
    },
  });
}
