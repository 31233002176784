import enquire from 'enquire.js';
import queryString from 'query-string';
import compare from 'just-compare';
import { md } from '../variables';
import { disableScroll, enableScroll } from '../lib/scroll';
import { CatalogPageRequestParams, getCatalogPage } from '../api/catalog';
import { ecommerceSendSearch } from '../lib/enhancedEcommerce';
import { formatPrice } from '../lib/helpers';
import { catalogFilterChangedEvent } from '../lib/events';

type CategorySlug = string;
type CatalogSorting = 'popular' | 'price_desc' | 'price_asc' | 'new';
type FilterType = 'boolean' | 'numeric' | 'multiselect';

type FilterParams = {
  name: string;
  label: string;
  type: FilterType;
  filter_type: FilterType;
  postfix?: string;
  is_in_filter: boolean;
  is_in_description: boolean;
  filter_include_categories?: CategorySlug[];
  filter_exclude_categories?: CategorySlug[];
  options?: [];
};

type CatalogComponentParams = {
  page: number;
  totalPages: number;
  totalProducts: number;
  categoryId?: number;
  currentPageUrl: string;
  search: string;
  sortingDefault: CatalogSorting;
  sorting: CatalogSorting;
  filterOptions: { [key: string]: FilterParams };
  filterValues: { [key: string]: any };
};

export function catalog(Alpine) {
  Alpine.data('catalog', catalogComponent);

  function catalogComponent({
    page = 1,
    totalPages = 1,
    totalProducts = 1,
    categoryId = null,
    currentPageUrl = '',
    search = '',
    filterOptions = {},
    filterValues = {},
    sortingDefault = 'popular',
    sorting = sortingDefault,
  }: CatalogComponentParams) {
    return {
      page: page,
      totalPages: totalPages,
      totalProducts: totalProducts,
      currentPageUrl: currentPageUrl,
      categoryId: categoryId,
      sorting: sorting,
      numericRanges: {},
      search: search,
      filterOptions: filterOptions,
      filterValues: { ...filterValues },
      forceApplyFilters: false,
      appliedFilters: {},
      isLoading: false,
      isFilterOpen: false,
      filterSectionOpened: null,
      isDesktopMode: false,
      collapsedFilterSections: Alpine.$persist([]).as('catalogFilterCollapsedSections'),
      shownCategoryGroups: Alpine.$persist([]).as(`catalogShownCategoryGroups_${categoryId}`),
      queryString: '',
      append: false,
      response: null,
      get normalizedFilterValues() {
        const filters = {};
        for (let name in this.filterValues) {
          if (this.isFilterSelected(name)) {
            filters[name] = this.filterValues[name];
          }
        }
        return filters;
      },
      init() {
        console.log('init');
        this.initAllFilters();

        console.log('page', this.page);
        console.log('sort', this.sorting);
        console.log('selected filters', { ...this.normalizedFilterValues });
        console.log('filter options', { ...this.filterOptions });
        enquire.register(`screen and (min-width: ${md}px)`, {
          match: () => {
            this.isDesktopMode = true;
            this.closeFilter();
          },
          unmatch: () => (this.isDesktopMode = false),
        });

        this.$watch('isFilterOpen', (isOpen) => {
          if (isOpen) {
            disableScroll();
          } else {
            enableScroll();
          }
        });

        this.applyFilter();
        this.$watch('normalizedFilterValues', () => {
          if (
            this.forceApplyFilters ||
            window.innerWidth >= md ||
            this.isFilterChanged('celebrities')
          ) {
            this.forceApplyFilters = false;
            this.applyFilter();
          }
          console.log('selected filters', this.normalizedFilterValues);
        });

        this.$watch('numericRanges', (ranges: { [idx: string]: string }) => {
          for (const [filter, value] of Object.entries(ranges)) {
            const vals = value.split('-');
            const from = parseInt(vals[0], 10);
            const to = parseInt(vals[1], 10);
            const fromName = `${filter}_from`;
            const toName = `${filter}_to`;

            if (from) {
              this.filterValues[fromName] = from;
            } else {
              delete this.filterValues[fromName];
            }

            if (to) {
              this.filterValues[toName] = to;
            } else {
              delete this.filterValues[toName];
            }
          }
        });
        this.updateNumericRangeSwitches();

        this.$watch('appliedFilters, page, sorting', async (prev) => {
          this.updateTitle();
          this.updateQueryString();
          this.updateNumericRangeSwitches();
          this.isLoading = true;

          const params: CatalogPageRequestParams = {
            page: this.page,
            current_page_url: currentPageUrl,
            sorting: this.sorting,
            ...this.appliedFilters,
          };

          if (this.categoryId) params.categoryId = this.categoryId;
          if (this.search.length > 0) params.filter_search = this.search;

          console.log('products request', params);
          const products = await getCatalogPage(params);
          console.log('product response', products);
          console.log('pages', this.page, products.totalPages);

          if (this.append) {
            this.$refs.catalogGrid.insertAdjacentHTML('beforeend', products.html.products);
            this.append = false;
          } else {
            this.$refs.catalogGrid.innerHTML = products.html.products;
          }
          this.$refs.catalogPagination.innerHTML = products.html.pagination;
          this.$refs.catalogFilterText.innerHTML = products.html.filterText;

          this.totalProducts = products.totalProducts;
          this.totalPages = products.totalPages;
          this.filterOptions = products.filter;
          this.isLoading = false;
        });
        this.updateQueryString();

        if (search.length > 0) ecommerceSendSearch(search);
      },
      async loadMore() {
        this.append = true;
        this.page++;
      },
      gotoPage(page) {
        this.page = page;
        this.scrollToTop();
      },
      gotoPrevPage() {
        if (this.page > 1) this.page--;
        this.scrollToTop();
      },
      gotoNextPage() {
        if (this.page < this.totalPages) this.page++;
        this.scrollToTop();
      },
      scrollToTop() {
        document
          .querySelector('.category__title')
          .scrollIntoView({ behavior: 'smooth', block: 'start' });
      },
      updateNumericRangeSwitches() {
        for (const [filter, options] of Object.entries(filterOptions)) {
          if (options.type === 'numeric') {
            const fromFilter = `${filter}_from`;
            const toFilter = `${filter}_to`;
            const rangeVal = `${this.appliedFilters[fromFilter] ?? ''}-${
              this.appliedFilters[toFilter] ?? ''
            }`;
            if (rangeVal !== '-') {
              this.numericRanges[filter] = rangeVal;
            }
          }
        }
      },
      updateTitle() {
        const title = document.title.replace(/\s*\| страница \d+/i, '');
        if (this.page > 1) {
          document.title = title + ` | страница ${this.page}`;
        } else {
          document.title = title;
        }
      },
      updateQueryString() {
        const qsParams = { ...this.appliedFilters };
        if (this.sorting !== sortingDefault) {
          qsParams.sorting = this.sorting;
        }

        if (this.search) {
          qsParams.filter_search = this.search;
        }

        console.log('qs', qsParams);
        let qs = queryString.stringify(qsParams, {
          arrayFormat: 'bracket',
          skipNull: true,
          skipEmptyString: true,
        });
        this.queryString = qs ? `?${qs}` : '';

        let url = this.currentPageUrl;
        if (this.page > 1) url = `${url}page/${this.page}/`;
        if (qs.length > 1) url = `${url}?${qs}`;

        window.history.pushState(null, null, url);
      },
      openFilter() {
        this.isFilterOpen = true;
      },
      closeFilter() {
        this.isFilterOpen = false;

        // wait for menu closing animation to finish
        // before resetting selected submenu
        setTimeout(() => (this.filterSectionOpened = null), 200);
      },
      filterSwitchSection(section) {
        if (this.isDesktopMode) {
          if (!this.collapsedFilterSections.includes(section)) {
            this.collapsedFilterSections.push(section);
          } else {
            this.collapsedFilterSections = this.collapsedFilterSections.filter(
              (s) => s !== section
            );
          }
        } else {
          this.filterSectionOpened = section;
        }
      },
      filterBack() {
        this.filterSectionOpened = null;
      },
      initFilterValue(name: string, type: string) {
        switch (type) {
          case 'multiselect':
            this.filterValues[name] = [];
            break;
          case 'boolean':
            this.filterValues[name] = false;
            break;
          default:
            delete this.filterValues[name];
        }
      },
      initAllFilters(reset: boolean = false) {
        Object.keys(this.filterOptions).forEach((name) => {
          const params = this.filterOptions[name];

          if (reset) {
            if (params.type === 'numeric') {
              this.initFilterValue(`${name}_from`, params.type);
              this.initFilterValue(`${name}_to`, params.type);
            } else {
              this.initFilterValue(name, params.type);
            }
          } else {
            if (params.type === 'numeric') {
              const fromName = `${name}_from`;
              const toName = `${name}_to`;
              if (!this.filterValues.hasOwnProperty(fromName))
                this.initFilterValue(fromName, 'numeric');
              if (!this.filterValues.hasOwnProperty(toName))
                this.initFilterValue(toName, 'numeric');
              return;
            }

            if (name in this.filterValues) return;

            this.initFilterValue(name, params.type);
          }
        });
      },
      clearFilter(name: string, value?: string) {
        const params = this.filterOptions[name];
        switch (params.type) {
          case 'multiselect':
            this.removeFilterChoice(name, value);
            break;
          case 'numeric':
            const rangeName = `${name}_${value}`;
            this.initFilterValue(rangeName, params.type);
            if (rangeName in this.appliedFilters) delete this.appliedFilters[rangeName];
            break;
          default:
            this.initFilterValue(name, params.type);
            if (name in this.appliedFilters) delete this.appliedFilters[name];
        }
      },
      removeFilterChoice(name: string, value: string) {
        this.forceApplyFilters = true;
        this.filterValues[name] = this.filterValues[name].filter((v) => v !== value);
      },
      clearAllFilters() {
        this.forceApplyFilters = true;
        this.initAllFilters(true);
        console.log('clear', this.filterValues, this.normalizedFilterValues);
      },
      isFilterChanged(name: string) {
        const selectedValue = this.normalizedFilterValues?.[name];
        const appliedValue = this.appliedFilters?.[name];

        return !compare(selectedValue, appliedValue);
      },
      areFiltersChanged(names: string[] = null) {
        return names
          ? names.some((name) => this.isFilterChanged(name))
          : !compare(this.normalizedFilterValues, this.appliedFilters);
      },
      isFilterSelected(name: string) {
        if (!this.filterValues.hasOwnProperty(name)) return false;

        const value = this.filterValues[name];
        if (Array.isArray(value)) {
          if (value.length > 0) return true;
        } else {
          if (value) return true;
        }

        return false;
      },
      applyFilter() {
        document.dispatchEvent(catalogFilterChangedEvent());
        if (this.areFiltersChanged()) {
          this.page = 1;
        }
        this.filterSectionOpened = null;
        this.appliedFilters = { ...this.normalizedFilterValues };
      },
      getSetFiltersCount() {
        return Object.keys(this.appliedFilters).length;
      },
      isAnyFilterSet() {
        return this.getSetFiltersCount() > 0;
      },
      isFilterSet(name) {
        return name in this.appliedFilters;
      },
      setFilter(name: string, value: string) {
        this.filterValues[name] = value;
      },
      getChoiceLabel(filter: string, value: string): string {
        const options = this.filterOptions?.[filter];
        // FIXME: the can be a race condition, when filterOptions does not contain selected choice
        //  due to user clicking filter options too fast
        const label = options?.choices?.[value]?.label;
        return label ? label : value;
      },
      getFilterChips() {
        let chips = [];

        Object.entries(filterOptions).forEach(([filter, options]) => {
          if (options.type === 'numeric') {
            const fromFilter = `${filter}_from`;
            const toFilter = `${filter}_to`;
            const label = options.label;
            const postfix = options.postfix;

            const from = this.appliedFilters[fromFilter];
            if (from) {
              chips.push({
                filter,
                label,
                value: `от ${formatPrice(from, { withoutSymbol: true })} ${postfix}`,
                variant: 'from',
              });
            }

            const to = this.appliedFilters[toFilter];
            if (to) {
              chips.push({
                filter,
                label,
                value: `до ${formatPrice(to, { withoutSymbol: true })} ${postfix}`,
                variant: 'to',
              });
            }

            return;
          }

          if (!this.appliedFilters[filter]) return;
          const value = this.appliedFilters[filter];

          if (options.type === 'multiselect') {
            (value as string[]).forEach((variant) => {
              chips.push({
                filter,
                label: options.label,
                value: this.getChoiceLabel(filter, variant),
                variant,
              });
            });
          } else if (options.type === 'boolean') {
            chips.push({ filter, label: '', value: options.label });
          } else {
            chips.push({ filter, label: options.label, value });
          }
        });

        return chips;
      },
      isCategoryGroupShown(name: string): boolean {
        return this.shownCategoryGroups.includes(name);
      },
      toggleCategoryGroup(name: string) {
        if (this.isCategoryGroupShown(name)) {
          this.shownCategoryGroups = this.shownCategoryGroups.filter((group) => group !== name);
        } else {
          this.shownCategoryGroups = [...this.shownCategoryGroups, name];
        }
      },
    };
  }
}
