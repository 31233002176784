import type { Alpine } from 'alpinejs';
import Swiper, { FreeMode, Navigation, Pagination, Grid, SwiperOptions } from 'swiper';

type SliderComponentParams = {
  swiper?: SwiperOptions;
};

/**
 * Стандартный компонент слайдера на базе swiper.
 *
 * @param Alpine
 */
export function slider(Alpine: Alpine) {
  Alpine.data('slider', sliderComponent);

  function sliderComponent(params: SliderComponentParams = {}) {
    const defaults: SwiperOptions = {
      modules: [],
      direction: 'horizontal',
      freeMode: true,
      slidesPerView: 'auto',
    };
    params.swiper = { ...defaults, ...(params.swiper || {}) };

    return {
      swiper: null,
      init() {
        this.$nextTick(() => {
          if (this.$refs.swiper) this.initSwiper();
        });
      },
      initSwiper() {
        if (params.swiper.freeMode) {
          params.swiper.modules.push(FreeMode);
        }

        if (params.swiper.grid) {
          params.swiper.modules.push(Grid);
        }

        if (this.$refs.pagination) {
          params.swiper.modules.push(Pagination);
          params.swiper.pagination = {
            el: this.$refs.pagination,
            clickable: true,
            dynamicBullets: true,
            dynamicMainBullets: 2,
          };

          this.$root.classList.add('has-pagination');
        }

        if (this.$refs.prev && this.$refs.next) {
          params.swiper.modules.push(Navigation);
          params.swiper.navigation = {
            nextEl: this.$refs.next,
            prevEl: this.$refs.prev,
          };
        }

        this.swiper = new Swiper(this.$refs.swiper, params.swiper);
      },
      destroy() {
        if (this.swiper) this.swiper.destroy();
      },
    };
  }
}
