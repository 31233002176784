import { getPHPSessionId } from './phpSession';

export function sendMetrikaUserParams() {
  // wait for Metrika script to load in GTM and send user params
  const waitYm = setInterval(() => {
    if (window.ym) {
      clearInterval(waitYm);
      ym(22556596, 'userParams', {
        sessionId: getPHPSessionId(),
      });
    }
  }, 500);
}
