import { timeout } from '../lib/timeout';
import { showToastEvent } from '../lib/events';

export type Emoji = 'face-smile' | 'face-sad';
export type ToastContent = { text: string; emoji: Emoji };

export function toast(Alpine) {
  Alpine.data('toast', toastComponent);

  function toastComponent() {
    return {
      text: '',
      emoji: null,
      isShown: false,
      timeout: null,
      async close() {
        this.isShown = false;
        await timeout(200); // wait for hide animation to finish
        this.text = '';
        this.emoji = null;
      },
      show(text, emoji?: Emoji, duration = 2000) {
        this.isShown = true;
        this.text = text;
        this.emoji = emoji;

        if (this.timeout) {
          clearTimeout(this.timeout);
          this.timeout = null;
        }

        this.timeout = setTimeout(() => this.close(), duration);
      },
    };
  }
}

export function showToast(text: string, emoji: Emoji = null) {
  document.dispatchEvent(showToastEvent({ text, emoji }));
}
