import Alpine from 'alpinejs';
import { CartItem } from './cart';

type OfferId = string;
type OfferQuantity = number;

type AnyQueryCartState = { [key: OfferId]: OfferQuantity };
type WishlistState = Array<OfferId>;

export type DigiLayer = {
  cartState: AnyQueryCartState;
  addToCart: (offerId: string) => Promise<boolean>;
  removeFromCart: (offerId: string) => Promise<boolean>;
  wishlistState: WishlistState;
  addToWishlist: (offerId: string) => Promise<boolean>;
  removeFromWishlist: (offerId: string) => Promise<boolean>;
  showBundleComponentsDialog: (offerId: string) => Promise<void>;
};

export const digiLayer: DigiLayer = {
  cartState: {},
  addToCart,
  removeFromCart,
  wishlistState: [],
  addToWishlist,
  removeFromWishlist,
  showBundleComponentsDialog,
};

export function initAnyQuery() {
  window.digiLayer = digiLayer;

  Alpine.effect(() => {
    updateCart();
  });

  Alpine.effect(() => {
    updateWishlist();
  });
}

function updateCart() {
  const items = Alpine.store('cart').list();
  digiLayer.cartState = {};
  items.forEach((item) => {
    digiLayer.cartState[`${item.productId}`] = item.quantity;
  });
}

function updateWishlist() {
  digiLayer.wishlistState = Alpine.store('favorites').items.map((item) => `${item}`);
}

export async function addToCart(offerId: string): Promise<boolean> {
  const id = parseInt(offerId, 10);
  const item = Alpine.store('cart').get(id) as CartItem;
  if (item) {
    await Alpine.store('cart').setQuantity(id, item.quantity + 1);
  } else {
    await Alpine.store('cart').add(id, { ecommerceList: 'Anyquery' });
  }
  updateCart();
  return true;
}

export async function removeFromCart(offerId: string): Promise<boolean> {
  const id = parseInt(offerId, 10);
  await Alpine.store('cart').remove(id);
  updateCart();
  return true;
}

export async function addToWishlist(offerId: string): Promise<boolean> {
  const id = parseInt(offerId, 10);
  await Alpine.store('favorites').add(id);
  return true;
}

export async function removeFromWishlist(offerId: string): Promise<boolean> {
  const id = parseInt(offerId, 10);
  await Alpine.store('favorites').remove(id);
  return true;
}

export async function showBundleComponentsDialog(offerId: string) : Promise<void> {
  const id = parseInt(offerId, 10);
  await Alpine.store('infoDialog').showBundleComponents(id);
}
