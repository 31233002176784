import * as Sentry from '@sentry/browser';
import 'core-js/proposals/string-replace-all-stage-4';
import './lib/abortcontrollerPolyfill';

import Alpine from 'alpinejs';
import persist from '@alpinejs/persist';
import mask from '@alpinejs/mask';
import intersect from '@alpinejs/intersect';
import morph from '@alpinejs/morph';
import focus from '@alpinejs/focus';

import './components/detailsGroup';
import './components/category-cards';
import './components/productGallery';

import { processImpressionsQueue } from './lib/enhancedEcommerce';

import { directives } from './directives';

import { commonStore } from './stores/common';
import { checkoutStore } from './stores/checkout';
import { headerStore } from './stores/header';
import { cartStore } from './stores/cart';
import { favoritesStore } from './stores/favorites';
import { infoDialogStore } from './stores/infoDialog';

import { floristConsultDialog } from './components/floristConsultDialog';
import { cart } from './components/cart';
import { productSlider } from './components/productSlider';
import { productsSliderSlim } from './components/productsSliderSlim';
import { select } from './components/select';
import { input } from './components/input';
import { inputDate } from './components/inputDate';
import { inputAddress } from './components/inputAddress';
import { productCard } from './components/productCard';
import { tooltip } from './components/tooltip';
import { catalog } from './components/catalog';
import { filterChips } from './components/filterChips';
import { catalogMenu } from './components/catalogMenu';
import { customPayment } from './components/customPayment';
import { productDetails } from './components/productDetails';
import { product } from './components/product';
import { checkoutSuccess } from './components/checkoutSuccess';
import { bx24widget } from './components/bx24widget';
import { toast } from './components/toast';
import { headerPanel } from './components/headerPanel';
import { topHeaderMenu } from './components/topHeaderMenu';
// import { searchbar } from './components/searchbar';
import { searchbarAnyQuery } from './components/searchbarAnyQuery';
import { inputTel } from './components/inputTel';
import { dialog } from './components/dialog';
import { lettersSlider } from './components/lettersSlider';
import { timeSelect } from './components/timeSelect';
import { dateSelect } from './components/dateSelect';
import { reviewCard } from './components/reviewCard';
import { reviews } from './components/reviews';
import { addReviewDialog } from './components/addReviewDialog';
import { imageUploader } from './components/imageUploader';
import { imageAttach } from './components/imageAttach';
import { mobileHorizontalScroller } from './components/mobileHorizontalScroller';
import { galleryDialog } from './components/galleryDialog';
import { reviewsSlider } from './components/reviewsSlider';
import { tabs } from './components/tabs';
import { dropdown } from './components/dropdown';
import { promotionsSlider } from './components/promotionsSlider';
import { cardsSlider } from './components/cardsSlider';
import { subcategories } from './components/subcategories';
import { digiLayer, initAnyQuery } from './lib/anyQuery';
import { blogPost } from './components/blogPost';
import { toTop } from './components/toTop';
import { browserFeatures } from './lib/browserFeatures';
import { getPHPSessionId } from './lib/phpSession';
import { wpRESTGet } from './lib/wpREST';
import { getProduct } from './api/catalog';
import { disableIosTextFieldZoom } from './lib/browser';
import { preloadTemplate } from './components/preloadTemplate';
import { countdown } from './components/countdown';
import { slider } from './components/slider';
import { sendMetrikaUserParams } from './lib/yandexMetrika';

if (wpParams.environment === 'production') {
  Sentry.init({
    dsn: 'https://1df361df3c8847f4989dbe730d932305@o1017661.ingest.us.sentry.io/5983608',
    integrations: [Sentry.browserTracingIntegration()],
    environment: wpParams.environment,
    tracesSampleRate: 1.0,
    allowUrls: [/https?:\/\/(www\.)?newflora\.ru/],
    initialScope: { user: { id: getPHPSessionId() } },

    // events filtering
    beforeSend(event: Sentry.Event) {
      // add additional information to all events
      event.extra = {
        ...(event.extra ?? {}),
        browserFeatures: browserFeatures(),
      };

      const exception = event?.exception?.values?.[0];
      if (!exception) return event;

      // exceptions that don't have a stacktrace and are littering the issues list
      const ignoreExceptions = [
        { type: 'TypeError', value: 'Failed to fetch' },
        { type: 'HTTPError', value: 'Request failed with status code 502' }, // CF
        { type: 'UnhandledRejection', value: 'Non-Error promise rejection captured with value: ' },
        {
          type: 'UnhandledRejection',
          value: 'Non-Error promise rejection captured with value: undefined',
        },
        { type: 'AbortError', value: 'The operation was aborted.' },
        { type: 'Error', value: 'AbortError: The operation was aborted.' },
        { type: 'NotSupportedError', value: 'Permissions::query does not support this API' }, // apple mail
        { type: 'NotSupportedError', value: 'The operation is not supported.' }, // apple mail
        { type: 'TypeError', value: 'NetworkError when attempting to fetch resource.' },
        { type: 'TypeError', value: 'Load failed' },
        { type: 'TypeError', value: 'отменено' },
        { type: 'TypeError', value: 'TypeError: Сетевое соединение потеряно.' },
        { type: 'Event', value: 'Event `Event` (type=error) captured as promise rejection' }, // https://github.com/getsentry/sentry-javascript/issues/2546#issuecomment-703331367

        // IndexedDB errors (we don't use it anyway)
        { type: 'Error', value: 'UnknownError: Unable to open database file on disk' },
        {
          type: 'Error',
          value: 'UnknownError: The user denied permission to access the database.',
        },
        { type: 'Error', value: 'UnknownError: Database deleted by request of the user' },
        {
          type: 'Error',
          value: 'UnknownError: Internal error opening backing store for indexedDB.open.',
        },
        {
          type: 'Error',
          value:
            'QuotaExceededError: Encountered full disk while opening backing store for indexedDB.open.',
        },
      ];
      for (let ignoredException of ignoreExceptions) {
        if (
          exception.type === ignoredException.type &&
          exception.value === ignoredException.value
        ) {
          return null;
        }
      }

      // bots messing around with XHR requests
      if (exception.type === 'HTTPError') {
        const ua = window.navigator.userAgent;
        if (ua.includes('bingbot') || ua.includes('applebot') || ua.includes('Googlebot')) {
          return null;
        }
      }

      // stacktrace
      const frames = exception.stacktrace?.frames ?? [];
      for (let frame of frames) {
        // IOS safari throwing at PostalAddress microdata
        if (frame.function.match(/extractFilteredSchemaValuesFromMicroData/g)) {
          return null;
        }
      }

      return event;
    },
  });
}

// @ts-ignore
window.Alpine = Alpine;
Alpine.plugin(persist);
Alpine.plugin(mask);
Alpine.plugin(intersect);
Alpine.plugin(morph);
Alpine.plugin(focus);

Alpine.plugin(directives);

Alpine.plugin(commonStore);
Alpine.plugin(headerStore);
Alpine.plugin(cartStore);
Alpine.plugin(checkoutStore);
Alpine.plugin(favoritesStore);
Alpine.plugin(infoDialogStore);

Alpine.plugin(headerPanel);
Alpine.plugin(topHeaderMenu);
// Alpine.plugin(searchbar);
Alpine.plugin(searchbarAnyQuery);
Alpine.plugin(floristConsultDialog);
Alpine.plugin(toast);
Alpine.plugin(product);
Alpine.plugin(cart);
Alpine.plugin(productSlider);
Alpine.plugin(productsSliderSlim);
Alpine.plugin(select);
Alpine.plugin(input);
Alpine.plugin(inputTel);
Alpine.plugin(inputDate);
Alpine.plugin(inputAddress);
Alpine.plugin(productCard);
Alpine.plugin(tooltip);
Alpine.plugin(catalog);
Alpine.plugin(filterChips);
Alpine.plugin(catalogMenu);
Alpine.plugin(customPayment);
Alpine.plugin(productDetails);
Alpine.plugin(checkoutSuccess);
Alpine.plugin(bx24widget);
Alpine.plugin(dialog);
Alpine.plugin(lettersSlider);
Alpine.plugin(timeSelect);
Alpine.plugin(dateSelect);
Alpine.plugin(reviewCard);
Alpine.plugin(reviews);
Alpine.plugin(reviewsSlider);
Alpine.plugin(addReviewDialog);
Alpine.plugin(imageUploader);
Alpine.plugin(imageAttach);
Alpine.plugin(mobileHorizontalScroller);
Alpine.plugin(galleryDialog);
Alpine.plugin(tabs);
Alpine.plugin(dropdown);
Alpine.plugin(promotionsSlider);
Alpine.plugin(cardsSlider);
Alpine.plugin(subcategories);
Alpine.plugin(blogPost);
Alpine.plugin(toTop);
Alpine.plugin(preloadTemplate);
Alpine.plugin(countdown);
Alpine.plugin(slider);

setTimeout(() => Alpine.start(), 0); // splits big js task

initAnyQuery();
setInterval(() => processImpressionsQueue(), 500);

const userPhone = Alpine.store('checkout').form.buyerPhone;
if (userPhone) Sentry.setUser({ username: userPhone });

disableIosTextFieldZoom();
sendMetrikaUserParams();

console.log('JS inited');
