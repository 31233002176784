import Swiper, { Lazy, Pagination } from 'swiper';
import { md } from '../variables';
import {
  ecommerceAddToWishlist,
  EcommerceImpressionData,
  sendEcommerceImpressions,
  sendImpressionClick,
} from '../lib/enhancedEcommerce';
import { timeout } from '../lib/timeout';
import { getProduct } from '../api/catalog';

type ProductCardParams = {
  id: number;
  ecommerceImpression?: EcommerceImpressionData;
};

export function productCard(Alpine) {
  Alpine.data('productCard', productCardComponent);

  function productCardComponent({ id, ecommerceImpression = null }: ProductCardParams) {
    return {
      swiper: null,
      slidesCount: 0,
      isContentsDialogOpen: false,
      isFavorite: false,
      init() {
        this.$watch('$store.favorites.items', (items) => {
          this.isFavorite = items.includes(id);
        });
      },
      initSlider() {
        if (!this.$refs.slider) return;

        this.swiper = new Swiper(this.$refs.slider, {
          modules: [Pagination, Lazy],
          lazy: {
            elementClass: 'swiper-slide picture img',
          },
          slidesPerView: 1,
          spaceBetween: 16,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          breakpoints: {
            [md]: {
              allowTouchMove: false,
            },
          },
        });
        this.slidesCount = this.swiper.slides.length;
      },
      mouseMove(event) {
        if (!this.swiper) return;
        if (window.innerWidth < md) return;

        const x = event.pageX - this.$refs.slider.getBoundingClientRect().left;
        const width = this.swiper.width;
        const regionWidth = width / this.slidesCount;
        const slideNumber = Math.floor(x / regionWidth);
        this.swiper.slideTo(slideNumber, 0);
      },
      onView() {
        if (ecommerceImpression) {
          sendEcommerceImpressions([ecommerceImpression]);
        }
      },
      async goto(link) {
        sendImpressionClick(ecommerceImpression);
        await timeout(400);
        window.location = link;
      },
      openContentsDialog() {
        this.$store.infoDialog.showBundleComponents(id);
      },
      destroy() {
        if (this.swiper) this.swiper.destroy();
      },
      async toggleFavorite() {
        if (!this.$store.favorites.has(id)) {
          ecommerceAddToWishlist(ecommerceImpression);
        }

        await this.$store.favorites.toggle(id);
      },
    };
  }
}
